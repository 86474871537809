<!-- eslint-disable -->
<template>
  <loading v-model:active="isLoading" :is-full-page="fullPage" />
    <div class="row">
      <div class="col-sm-12 col-md-6">
        <div class="card">
          <div class="card-header card-default">
            <p id="idExsampel" class="text-muted" >
              Halaman Registrasi Akun SPADA
            </p>
          </div>
          <div class="card-body padding-t-0">
            <form  role="form" method="post" action="">
              <div class="form-group ">
                <label>Nama Lengkap</label>
                <input type="text" class="form-control form-control-rounded" v-model="valName.user_nama" :readonly="true">
              </div>

              <div class="form-group">
                <label>Alamat Email</label>
                <input type="text" class="form-control col-sm-12 col-md-10" placeholder="Masukan Email Aktif" v-model="valName.email">
                
              </div>
              <div class="form-group">
                <label>Username Login</label>
                <input id="input-id-1" type="text" class="form-control col-sm-12 col-md-6" v-model="valName.username" :readonly="true">
              </div>
              <div class="form-group">
                <label>Konfirmasi Pendaftaran </label>
                <input type="password" name="password" class="form-control col-sm-12 col-md-6" placeholder="Masukan Password SIAKAD" v-model="valName.password">
                <small class="text-muted">Masukan Akun SSO agar kami bisa memastikan bahwa benar saudara adalah pemelik akun ini.</small>
              </div>
              <button type="button" v-on:click="submit" class="btn btn-success btn-rounded box-shadow">Registrasi Akun</button> &nbsp;
              <router-link to="/info-akun-spada" class="btn btn-warning btn-rounded box-shadow">Kembali</router-link>
            </form>
          </div>
        </div>
      </div>
    </div>
</template>
<script>

/* global $ */
import Loading from 'vue-loading-overlay'
import { useStore } from 'vuex'
import { addAkun } from '@/services/spada.service'
export default {
  name: 'SpadaRegistrasiAkun',
  components: {
    Loading
  },
  data () {
    const store = useStore()
    const users = store.getters.user

    return {
      valName: {
        user_id: users.user_id,
        user_nama: users.nama_lengkap,
        email: users.user_email,
        username: users.username,
        password: ''
      },
      isLoading: false,
      errorMsg: '',
      fullPage: true
    }
  },
  methods: {
    async submit () {
      this.isLoading = true
      addAkun(this.valName).then(response => {
        if (response.error_code === 200) {
          $.toast({
            heading: 'Good job!',
            text: 'Data akun spada berhasil dibuat',
            position: 'top-right',
            loaderBg: '#fff',
            icon: 'success',
            hideAfter: 3000,
            stack: 1
          })
          this.isLoading = false
        } else {
          $.toast({
            heading: 'Error !',
            text: response.messages,
            position: 'top-right',
            loaderBg: '#fff',
            icon: 'error',
            hideAfter: 3000,
            stack: 1
          })
          this.isLoading = false
        }
      }).catch(error => {
        this.isLoading = false
        this.errorMsg = (error.response) ? {} : {}
      })
    }
  }
}
</script>
