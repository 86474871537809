import request from '../config/request'

export function getAkun (parameter) {
  return request.get('/spada/user', { params: parameter })
}

export function addAkun (parameter) {
  return request.post('/spada/useradd', parameter)
}

export function getKelas (parameter) {
  return request.get('/spada/kelas_prodi', { params: parameter })
}

export function getDetailCourse (parameter) {
  return request.get('/spada/kursusview', { params: parameter })
}

export function addPeserta (parameter) {
  return request.get('/spada/enroll', { params: parameter })
}
export function dltPeserta (parameter) {
  return request.get('/spada/unenroll', { params: parameter })
}
